<template>

  <div>
    <v-container fluid fill-height>
      <v-layout justify-center align-center>
        <v-card width="100%" outlined elevation-0>
          <v-card-title>
            Skins
            <!--<v-btn color="blue-grey" class="white&#45;&#45;text text-right ml-3 " @click="clearcache()">
              <v-icon left dark>save</v-icon>
              {{ $t('generic.clearcache') }}
            </v-btn>-->
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="search" label="Cerca.."></v-text-field>
          </v-card-title>
          <v-data-table :headers="headers" :items="getSkins" :search="search" item-key="_id"
                        class="elevation-0">
            <template v-slot:body="{ items }">
              <tbody>
              <tr v-for="item in items" :key="item._id">
                <td>{{ item.platform_id }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.website }}</td>
                <td>{{ item.showquickbet }}</td>
                <td>{{ item.quickbet }}</td>
                <td>{{ item.eventday }}</td>
                <td>{{ item.nextevent }}</td>
                <td>{{ item.cashout }}</td>
                <td>{{ item.cashoutlive }}</td>
                <td>{{ item.shockabsorber }}</td>
                <td>{{ item.icon }}</td>
                <td>{{ (item.active) ? 'Attivo' : 'Non attivo' }}</td>
                <td>{{ item.token_terminal != undefined ? item.token_terminal : null}}</td>
                <td>{{item.gameConsole != null ? item.gameConsole : 'default'}}</td>
                <td>{{item.featuredTournamentWidget != null ? item.featuredTournamentWidget : 'Non attivo'}}
                </td>
                <td>{{item.featuredTournamentAutoLoad != null ? item.featuredTournamentAutoLoad : 'Non attivo'}}
                </td>
                <td>{{item.bookieUserName != null ? item.bookieUserName : null}}</td>
                <td>{{item.bookiePassword != null ? item.bookiePassword : null}}</td>
                <td>{{item.enableClearCartAtPlaceBet != null ? item.enableClearCartAtPlaceBet : 'Non attivo'}}
                </td>
                <td>{{ (item.signUp) ? 'Attivo' : 'Non attivo' }}</td>
                <td>{{ (item.ai_safebet) ? 'Attivo' : 'Non attivo' }}</td>
                <td>{{item.widgetFieldUrl != null ? item.widgetFieldUrl : null}}</td>
                <td>{{item.widgetFieldUsername != null ? item.widgetFieldUsername : null}}</td>
                <td>{{item.widgetFieldPassword != null ? item.widgetFieldPassword : null}}</td>
                <td>{{item.enabledTaxOnWon != null ? item.enabledTaxOnWon : 'Non attivo'}}</td>
                <td>{{item.percentageTaxValueOnWon != null ? item.percentageTaxValueOnWon : 0}}</td>
                <td>{{item.maxTimeToDeleteBetMs != null ? item.maxTimeToDeleteBetMs : 180000}}</td>
                <td>{{item.enableAgencyCanPlayForUser != null ? item.enableAgencyCanPlayForUser : 'Non attivo'}}</td>

                <td>{{item.enableMonedero != null ? item.enableMonedero : 'Non attivo'}}</td>
                <td>{{item.enableNetUserBlockedCheck != null ? item.enableNetUserBlockedCheck : 'Non attivo'}}</td>
                <td>{{item.rejectBetTimeLiveOddExpired != null ? item.rejectBetTimeLiveOddExpired : 'Non attivo'}}</td>
                <td>{{item.minimumNumberDaysForPayBet != null ? item.minimumNumberDaysForPayBet : 0}}</td>
                <td>{{item.maximumNumberDaysForPayBet != null ? item.maximumNumberDaysForPayBet : 0}}</td>
                <td>{{item.retailCanPayTicketOfOtherRetail != null ? item.retailCanPayTicketOfOtherRetail : 'Non attivo'}}</td>
                <td>{{item.enableMobileBottomToolbar != null ? item.enableMobileBottomToolbar : 'Non attivo'}}</td>
                <td>{{item.assignRetailBetToAgency != null ? item.assignRetailBetToAgency : 'Attivo'}}</td>
                <td>{{item.disableCheckOnAntepostOdds != null ? item.disableCheckOnAntepostOdds : 'Non attivo'}}</td>
                <td>{{item.disableSessionCheck != null ? item.disableSessionCheck : 'Non attivo'}}</td>
                <td>{{item.explodeMarketOnMobileDevice != null ? item.explodeMarketOnMobileDevice : 'Non attivo'}}</td>
                <td>{{item.showMultiplicityLegendMobile != null ? item.showMultiplicityLegendMobile : 'Non attivo'}}</td>
                <td>{{item.enableTreeWithPlayerOutcomes != null ? item.enableTreeWithPlayerOutcomes : 'Non attivo'}}</td>
                <td>{{item.useServerSideRules != null ? item.useServerSideRules : 'Non attivo'}}</td>
                <td>{{item.proxyUrl != null ? item.proxyUrl : null}}</td>
                <td>{{item.allowIntegralLiveBet != null ? item.allowIntegralLiveBet : 'Non attivo'}}</td>
                <td>{{item.betCodeViewSelector != null ? item.betCodeViewSelector : 'long'}}</td>
                <td>{{item.useWalletAgency != null ? item.useWalletAgency : 'Attivo'}}</td>
                <td>{{item.disableIdleUICheck != null ? item.disableIdleUICheck : 'Non attivo'}}</td>
                <td width="120">
                  <v-icon class="mr-0" @click="openEdit(item)">
                    mdi-briefcase-edit
                  </v-icon>
                  <v-icon class="mr-0" @click="deleteItem(item)">
                    mdi-trash-can
                  </v-icon>
                </td>
              </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>

        <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color"
                    :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout"
                    :vertical="snackbar.mode === 'vertical'">
          {{ snackbar.text }}
          <v-btn dark text @click="snackbar.snackbar = false">
            Chiudi
          </v-btn>
        </v-snackbar>

        <v-btn fab bottom right color="secondary" dark fixed @click.prevent="openNew">
          <v-icon>add</v-icon>
        </v-btn>
      </v-layout>
    </v-container>

    <v-dialog persistent v-model="dialog" width="1000px">
      <v-card>
        <v-card-title class="grey lighten-4 py-4 title">
          {{ titleDialog }}
        </v-card-title>
        <v-container grid-list-sm class="pa-4">
          <v-layout row wrap>

            <v-flex xs6>
              <v-text-field label="Nome" v-model="form.name"
                            :error-messages="messages.name"></v-text-field>
            </v-flex>

            <v-flex xs6>
              <v-text-field label="Website" v-model="form.website" :error-messages="messages.website"
                            @input="putOnOtherFields()"></v-text-field>
            </v-flex>


            <v-flex xs6>
              <v-select :items="statuses" item-text="text" item-value="value" label="Stato"
                        v-model="form.active"></v-select>
            </v-flex>
            <v-flex xs6>
              <v-select :items="statuses" item-text="text" item-value="value" label="Quickbet"
                        v-model="form.quickbet"></v-select>
            </v-flex>
            <v-flex xs6>
              <v-select :items="statuses" item-text="text" item-value="value" label="Event day"
                        v-model="form.eventday"></v-select>
            </v-flex>
            <v-flex xs6>
              <v-select :items="statuses" item-text="text" item-value="value" label="Next event"
                        v-model="form.nextevent"></v-select>
            </v-flex>
            <v-flex xs6>
              <v-select :items="statuses" item-text="text" item-value="value"
                        label="Transfer agnecy to Quickbet" v-model="form.showquickbet"></v-select>
            </v-flex>
            <v-flex xs6>
              <v-select :items="statuses" item-text="text" item-value="value" label="Cashout prematch"
                        v-model="form.cashout"></v-select>
            </v-flex>
            <v-flex xs6>
              <v-select :items="statuses" item-text="text" item-value="value" label="Cashout live"
                        v-model="form.cashoutlive"></v-select>
            </v-flex>
            <v-flex xs6>
              <v-select :items="statuses" item-text="text" item-value="value" label="Shock absorber"
                        v-model="form.shockabsorber"></v-select>
            </v-flex>
            <v-flex xs6>
              <v-select :items="statuses" item-text="text" item-value="value" label="Void as Win"
                        v-model="form.voidAsLose"></v-select>
            </v-flex>
            <v-flex xs6>
              <v-text-field label="Agent type" v-model="form.settings.agent_type"></v-text-field>
            </v-flex>
            <v-flex xs3>
              <v-text-field label="Id partner" v-model="form.settings.id_partner"></v-text-field>
            </v-flex>
            <v-flex xs3>
              <v-text-field label="Skin id" v-model="form.settings.skin_id"></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-select :items="statuses" item-text="text" item-value="value"
                        label="Risk Management Algorithm" v-model="form.settings.enableRisk"></v-select>
            </v-flex>
            <v-flex xs6>
              <v-select :items="statuses" item-text="text" item-value="value" label="Check Time Live"
                        v-model="form.settings.enableTimeLive"></v-select>
            </v-flex>
            <v-flex xs6>
              <v-text-field label="Terminal token" v-model="form.tokenTerminal"></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field label="Save endpoint" v-model="form.settings.save_endpoint"></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field label="Sircms endpoint"
                            v-model="form.settings.sircms_endpoint"></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field label="Print endpoint" v-model="form.settings.print_endpoint"></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field label="Send Bet endpoint"
                            v-model="form.settings.send_bet_endpoint"></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-select :items="statusesOdds" item-text="text" item-value="value" label="Type Odds"
                        v-model="form.settings.typeOdds"></v-select>
            </v-flex>
            <v-flex xs6>
              <v-autocomplete :items="timezones" label="Timezone"
                              v-model="form.settings.timezone"></v-autocomplete>
            </v-flex>
            <v-flex xs6>
              <v-select :items="gameConsoles" item-text="text" item-value="value" label="Game Console"
                        v-model="form.gameConsole"></v-select>
            </v-flex>
            <v-flex xs6>
              <v-select :items="languages" item-text="text" item-value="value" label="Languages"
                        v-model="form.settings.language"></v-select>
            </v-flex>
            <v-flex xs12>
              <v-autocomplete
                      v-model="form.results"
                      :items="selectResult"
                      label="Result"

                      item-value="id"
                      item-text="name"
                      chips
                      deletable-chips
                      multiple
                      small-chips


              ></v-autocomplete>
            </v-flex>
            <v-flex xs6>
              <v-select :items="icons" item-text="text" item-value="value" label="Type icon"
                        v-model="form.icon"></v-select>
            </v-flex>
            <v-flex xs6>
              <v-select :items="statuses" item-text="text" item-value="value" label="Stream live"
                        v-model="form.settings.stream_live"></v-select>
            </v-flex>

            <v-flex xs6>
              <v-select :items="statuses" item-text="text" item-value="value" label="SignUp"
                        v-model="form.signUp"></v-select>
            </v-flex>
            <v-flex xs6>
              <v-select :items="statuses" item-text="text" item-value="value" label="Ai"
                        v-model="form.ai_safebet"></v-select>
            </v-flex>
            <v-flex xs6>
              <v-select :items="statuses" item-text="text" item-value="value"
                        label="Featured tournament widget"
                        v-model="form.featuredTournamentWidget"></v-select>
            </v-flex>

            <v-flex xs6>
              <v-select :items="statuses" item-text="text" item-value="value"
                        label="Featured tournament autoload"
                        v-model="form.featuredTournamentAutoLoad"></v-select>
            </v-flex>


            <v-flex xs6>
              <v-text-field label="Bookie username" v-model="form.bookieUserName"></v-text-field>
            </v-flex>

            <v-flex xs6>
              <v-text-field label="Bookie password" v-model="form.bookiePassword"></v-text-field>
            </v-flex>

            <v-flex xs6>
              <v-select :items="statuses" item-text="text" item-value="value"
                        label="Enable clear cart at place bet"
                        v-model="form.enableClearCartAtPlaceBet"></v-select>
            </v-flex>

            <v-flex xs6>
              <v-text-field label="Widget Url" v-model="form.widgetFieldUrl"></v-text-field>
            </v-flex>

            <v-flex xs6>
              <v-text-field label="Widget Username" v-model="form.widgetFieldUsername"></v-text-field>
            </v-flex>

            <v-flex xs6>
              <v-text-field label="Widget Password" v-model="form.widgetFieldPassword"></v-text-field>
            </v-flex>

            <v-flex xs6>
              <v-select :items="statuses" item-text="text" item-value="value" label="Enable tax on won"
                        v-model="form.enabledTaxOnWon"></v-select>
            </v-flex>

            <v-flex xs6>
              <v-text-field label="Tax value on won"
                            v-model="form.percentageTaxValueOnWon"></v-text-field>
            </v-flex>

            <v-flex xs6>
              <v-text-field label="Max time to delete a bet in ms"
                            v-model="form.maxTimeToDeleteBetMs"></v-text-field>
            </v-flex>

            <v-flex xs6>
              <v-select :items="statuses" item-text="text" item-value="value"
                        label="Enable agency can play for user"
                        v-model="form.enableAgencyCanPlayForUser"></v-select>
            </v-flex>

            <v-flex xs6>
              <v-select :items="statuses" item-text="text" item-value="value"
                        label="Enable monedero"
                        v-model="form.enableMonedero"></v-select>
            </v-flex>

            <v-flex xs6>
              <v-select :items="statuses" item-text="text" item-value="value"
                        label="Enable net user blocked check"
                        v-model="form.enableNetUserBlockedCheck"></v-select>
            </v-flex>

            <v-flex xs6>
              <v-select :items="statuses" item-text="text" item-value="value"
                        label="Enable reject bet on time live odd expired"
                        v-model="form.rejectBetTimeLiveOddExpired"></v-select>
            </v-flex>

            <v-flex xs6>
              <v-text-field label="Minimum number of days must pass to pay the bet"
                            v-model="form.minimumNumberDaysForPayBet"></v-text-field>
            </v-flex>

            <v-flex xs6>
              <v-text-field label="Maximum number of days to pay the bet"
                            v-model="form.maximumNumberDaysForPayBet"></v-text-field>
            </v-flex>

            <v-flex xs6>
              <v-select :items="statuses" item-text="text" item-value="value"
                        label="Enable retail can pay tickets of other retails"
                        v-model="form.retailCanPayTicketOfOtherRetail"></v-select>
            </v-flex>

            <v-flex xs6>
              <v-select :items="statuses" item-text="text" item-value="value"
                        label="Enable mobile bottom toolbar"
                        v-model="form.enableMobileBottomToolbar"></v-select>
            </v-flex>

            <v-flex xs6>
              <v-select :items="statuses" item-text="text" item-value="value"
                        label="Assign retail bet to agency"
                        v-model="form.assignRetailBetToAgency"></v-select>
            </v-flex>

            <v-flex xs6>
              <v-select :items="statuses" item-text="text" item-value="value"
                        label="Disable check on antepost odds"
                        v-model="form.disableCheckOnAntepostOdds"></v-select>
            </v-flex>

            <v-flex xs6>
              <v-select :items="statuses" item-text="text" item-value="value"
                        label="Disable check on session"
                        v-model="form.disableSessionCheck"></v-select>
            </v-flex>

            <v-flex xs6>
              <v-select :items="statuses" item-text="text" item-value="value"
                        label="Explode markets on mobile devices"
                        v-model="form.explodeMarketOnMobileDevice"></v-select>
            </v-flex>

            <v-flex xs6>
              <v-select :items="statuses" item-text="text" item-value="value"
                        label="Show multiplicity legend on mobile devices"
                        v-model="form.showMultiplicityLegendMobile"></v-select>
            </v-flex>

            <v-flex xs6>
              <v-select :items="statuses" item-text="text" item-value="value"
                        label="Enable tree with player outcomes"
                        v-model="form.enableTreeWithPlayerOutcomes"></v-select>
            </v-flex>

            <v-flex xs6>
              <v-text-field label="Proxy url" v-model="form.proxyUrl"></v-text-field>
            </v-flex>


            <v-flex xs6>
              <v-select :items="statuses" item-text="text" item-value="value"
                        label="Enable server side rules"
                        v-model="form.useServerSideRules"></v-select>
            </v-flex>

            <v-flex xs6>
              <v-select :items="statuses" item-text="text" item-value="value"
                        label="Enable integral live bet"
                        v-model="form.allowIntegralLiveBet"></v-select>
            </v-flex>

            <v-flex xs6>
              <v-select :items="selectBetCodeToShow" item-text="text" item-value="value"
                        label="Type of bet code to show"
                        v-model="form.betCodeViewSelector"></v-select>
            </v-flex>
          </v-layout>

          <v-flex xs6>
            <v-select :items="statuses" item-text="text" item-value="value"
                      label="Agencies use wallets"
                      v-model="form.useWalletAgency"></v-select>
          </v-flex>

          <v-flex xs6>
            <v-select :items="statuses" item-text="text" item-value="value"
                      label="Disable idle UI check"
                      v-model="form.disableIdleUICheck"></v-select>
          </v-flex>
        </v-container>

        <v-card-actions justify="end">
          <v-btn color="primary" @click="dialog = false">Annulla</v-btn>
          <v-btn color="secondary" @click.prevent="submit">Conferma</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
    <v-dialog v-model="dialogConfirm" max-width="290">
      <v-card>

        <v-card-title class="headline">{{ titleDialog }}</v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" @click="dialogConfirm = false">
            Annulla
          </v-btn>

          <v-btn color="secondary" @click="confirmDelete">
            Conferma
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import axios from 'axios'
  import {mapActions, mapGetters, mapMutations} from 'vuex';
  import timezone from '@/assets/timezone.json'

  const isNumber = (num) => (typeof (num) === 'number' || typeof (num) === "string" && num.trim() !== '') && !isNaN(num);

  export default {
    name: "Skins",
    data() {
      return {
        icons: [
          {'text': 'Black and white', value: "B/W"},
          {'text': 'Color', value: "Color"}
        ],
        timezones: timezone,
        search: '',
        statuses: [
          {'text': 'Attivo', value: true},
          {'text': 'Non Attivo', value: false}
        ],
        statusesOdds: [
          {'text': 'Decimali', value: false},
          {'text': 'Americane', value: true}
        ],
        gameConsoles: [
          'default', 'marketview'
        ],
        languages: [],
        pagination: {
          sortBy: 'platform_id',
          descending: true,
          rowsPerPage: 10
        },
        headers: [
          {text: 'Id', value: 'platform_id'},
          {text: 'Nome', value: 'name'},
          {text: 'Website', value: 'website'},
          {text: 'Transfer agency to Quickbet', value: 'showquickbet'},
          {text: 'Quickbet', value: 'quickbet'},
          {text: 'Event day', value: 'eventday'},
          {text: 'Next event', value: 'nextevent'},
          {text: 'Cashout prematch', value: 'cashout'},
          {text: 'Cashout live', value: 'cashoutlive'},
          {text: 'Shock absorber', value: 'shockabsorber'},
          {text: 'Icon', value: 'icon'},
          {text: 'Stato', value: 'active'},
          {text: 'Token terminal', value: 'token terminal', sortable: false},
          {text: 'Game console', value: 'game console', sortable: false},
          {text: 'Featured tournament widget', value: 'featuredTournamentWidget'},
          {text: 'Featured tournament autoload', value: 'featuredTournamentAutoLoad'},
          {text: 'Bookie username', value: 'bookie username', sortable: false},
          {text: 'Bookie password', value: 'bookie password', sortable: false},
          {text: 'Enable Clear Cart At Place Bet', value: 'enableClearCartAtPlaceBet'},
          {text: 'Signup', value: 'signUp'},
          {text: 'Ai', value: 'Ai'},
          {text: 'Widget field url', value: 'widgetFieldUrl'},
          {text: 'Widget username', value: 'widgetFieldUsername'},
          {text: 'Widget password', value: 'widgetFieldPassword'},
          {text: 'Enable tax on won', value: 'enabledTaxOnWon'},
          {text: 'Tax value on won', value: 'percentageTaxValueOnWon'},
          {text: 'Max time to delete a bet in ms', value: 'maxTimeToDeleteBetMs'},
          {text: 'Enable agency can play for user', value: 'enableAgencyCanPlayForUser'},
          {text: 'Enable monedero', value: 'enableMonedero'},
          {text: 'Enable net user blocked check', value: 'enableNetUserBlockedCheck'},
          {text: 'Enable reject bet on time live odd expired', value: 'rejectBetTimeLiveOddExpired'},
          {text: 'Min number of days must pass for pay the bet', value: 'minimumNumberDaysForPayBet'},
          {text: 'Max number of days for pay the bet', value: 'maximumNumberDaysForPayBet'},
          {text: 'Enable retail can pay tickets of other retails', value: 'retailCanPayTicketOfOtherRetail'},
          {text: 'Enable mobile bottom toolbar', value: 'enableMobileBottomToolbar'},
          {text: 'Assign retail bet to agency', value: 'assignRetailBetToAgency'},
          {text: 'Disable check on antepost odds', value: 'disableCheckOnAntepostOdds'},
          {text: 'Disable check on session', value: 'disableSessionCheck'},
          {text: 'Explode markets on mobile device', value: 'explodeMarketOnMobileDevice'},
          {text: 'Show multiplicity legend on mobile devices', value: 'showMultiplicityLegendMobile'},
          {text: 'Enable tree with player outcomes', value: 'enableTreeWithPlayerOutcomes'},
          {text: 'Enable server side rules', value: 'useServerSideRules'},
          {text: 'Proxy url', value: 'proxyUrl'},
          {text: 'Enable integral live bet', value: 'allowIntegralLiveBet'},
          {text: 'Bet code to show', value: 'betCodeViewSelector'},
          {text: 'Agencies use wallets', value: 'useWalletAgency'},
          {text: 'Disable idle UI check', value: 'disableIdleUICheck'},
          {text: 'Action', value: '', sortable: false}

        ],
        snackbar: {
          snackbar: false,
          color: '',
          mode: '',
          timeout: 6000,
          text: '',
        },

        titleDialog: '',

        dialog: false,
        dialogConfirm: false,

        idSkin: -1,
        actionForm: '',

        form: {
          name: '',
          website: '',
          active: true,
          quickbet: false,
          eventday: false,
          nextevent: false,
          showquickbet: false,
          cashout: false,
          cashoutlive: false,
          voidAsLose: false,
          icon: "B/W",
          results: [],
          settings: {
            agent_type: '',
            id_partner: '',
            skin_id: '',
            save_endpoint: '',
            print_endpoint: '',
            send_bet_endpoint: '',
            timezone: '',
            language: '',
            stream_live: false
          },
          tokenTerminal: null,
          gameConsole: null,
          featuredTournamentWidget: false,
          enableClearCartAtPlaceBet: false,
          signUp: false,
          ai_safebet: false,
          featuredTournamentAutoLoad: false,
          bookieUserName: null,
          bookiePassword: null,
          widgetFieldUrl: null,
          widgetFieldUsername: null,
          widgetFieldPassword: null,
          enabledTaxOnWon: false,
          percentageTaxValueOnWon: 0,
          enableAgencyCanPlayForUser: false,
          enableMonedero: false,
          enableNetUserBlockedCheck: false,
          rejectBetTimeLiveOddExpired: false,
          minimumNumberDaysForPayBet: 0,
          maximumNumberDaysForPayBet: 0,
          maxTimeToDeleteBetMs: 180000,
          retailCanPayTicketOfOtherRetail: false,
          enableMobileBottomToolbar: false,
          assignRetailBetToAgency: true,
          disableCheckOnAntepostOdds: false,
          disableSessionCheck: false,
          explodeMarketOnMobileDevice: false,
          showMultiplicityLegendMobile: false,
          enableTreeWithPlayerOutcomes: false,
          proxyUrl: null,
          useServerSideRules: false,
          allowIntegralLiveBet: false,
          betCodeViewSelector: 'long',
          useWalletAgency: true,
          disableIdleUICheck: false
        },
        deleteitem: '',
        show1: false,
        selectResult: [{
          id: 3,
          name: "Operator"
        },
          {
            id: 4,
            name: "Agency"
          },
          {
            id: 2,
            name: "User"
          },
          {
            id: 100,
            name: "Cashier"
          }

        ],
        messages: {
          name: [],
          website: [],
          active: [],
          /*settings: {
            agent_type: '',
            id_partner: '',
            skin_id: '',
            save_endpoint: '',
            print_endpoint: ''
          }*/
        },
        selectBetCodeToShow: [
          {'text': 'Long', value: 'long'},
          {'text': 'Short', value: 'short'}
        ],
      }
    },
    created() {
      this.resetForm();
    },
    mounted() {
      this.loadLanguageList();
      console.log('LAST UPDATED 14-11-2023')
    },
    computed: {
      ...mapGetters({
        'getAutenticate': 'profile/getAutenticate',
        'getToken': 'profile/getToken',
        'getUrl': 'settings/getUrl',
        'getUrlRule': 'settings/getUrlRule',
        'getPreload': 'utils/getPreload',
        'getSkins': 'skins/getList',
        'getLanguagesList': 'profile/getLanguagesList',
      }),
    },
    watch: {
      getLanguagesList() {
        this.loadLanguageList();
      }
    },
    methods: {
      ...mapMutations({}),
      ...mapActions({
        'setPreload': 'utils/setPreload',
        'fetchSkins': 'skins/fetchSkins',
        'cacheRules': 'cache/cacheRules',
      }),
      async clearcache() {
        await this.setPreload(true);
        //await this.cacheMarkets()
        await this.cacheRules()
        await this.setPreload(false);

      },
      openNew() {
        this.titleDialog = 'Nuova Skin';
        this.resetForm()
        this.form.settings.agent_type = 92
        this.form.settings.id_partner = 93
        this.actionForm = 'create';
        this.dialog = true;
      },

      putOnOtherFields() {
        this.form.settings.save_endpoint = this.form.website + '/bookie/gaim/sport/'
        this.form.settings.print_endpoint = this.form.website + '/receipts/receiptGeneral8mm.jsp?externalId='
      },

      openEdit(item) {
        this.titleDialog = 'Modifica Dati Skin';
        this.actionForm = 'update';

        this.idSkin = item.platform_id
        this.form.name = item.name
        this.form.website = item.website
        this.form.active = item.active
        this.form.quickbet = item.quickbet
        this.form.results = item.results
        this.form.nextevent = item.nextevent
        this.form.eventday = item.eventday
        this.form.showquickbet = item.showquickbet
        this.form.cashout = item.cashout
        this.form.cashoutlive = item.cashoutlive
        this.form.voidAsLose = item.voidAsLose
        this.form.settings = item.settings
        this.form.icon = item.icon
        this.form.shockabsorber = item.shockabsorber
        this.form.tokenTerminal = item.token_terminal != undefined ? item.token_terminal : null;
        this.form.gameConsole = item.gameConsole != null ? item.gameConsole : 'default';
        this.form.settings.stream_live = item.settings.stream_live != undefined ? item.settings.stream_live : false;
        this.form.featuredTournamentAutoLoad = item.featuredTournamentAutoLoad != null ? item.featuredTournamentAutoLoad : false;
        this.form.featuredTournamentWidget = item.featuredTournamentWidget != null ? item.featuredTournamentWidget : false;
        this.form.signUp = item.signUp != null ? item.signUp : false;
        this.form.ai_safebet = item.ai_safebet != null ? item.ai_safebet : false;
        this.form.bookieUserName = item.bookieUserName != undefined ? item.bookieUserName : null;
        this.form.bookiePassword = item.bookiePassword != undefined ? item.bookiePassword : null;
        this.form.enableClearCartAtPlaceBet = item.enableClearCartAtPlaceBet != null ? item.enableClearCartAtPlaceBet : false;
        this.form.widgetFieldUrl = item.widgetFieldUrl != undefined ? item.widgetFieldUrl : null;
        this.form.widgetFieldUsername = item.widgetFieldUsername != undefined ? item.widgetFieldUsername : null;
        this.form.widgetFieldPassword = item.widgetFieldPassword != undefined ? item.widgetFieldPassword : null;
        this.form.enabledTaxOnWon = item.enabledTaxOnWon != null ? item.enabledTaxOnWon : false;
        this.form.percentageTaxValueOnWon = item.percentageTaxValueOnWon != null ? item.percentageTaxValueOnWon : 0;
        this.form.enableAgencyCanPlayForUser = item.enableAgencyCanPlayForUser != null ? item.enableAgencyCanPlayForUser : false;
        this.form.maxTimeToDeleteBetMs = item.maxTimeToDeleteBetMs != null ? item.maxTimeToDeleteBetMs : 180000;
        this.form.enableMonedero = item.enableMonedero != null ? item.enableMonedero : false;
        this.form.enableNetUserBlockedCheck = item.enableNetUserBlockedCheck != null ? item.enableNetUserBlockedCheck : false;
        this.form.rejectBetTimeLiveOddExpired = item.rejectBetTimeLiveOddExpired != null ? item.rejectBetTimeLiveOddExpired : false;
        this.form.minimumNumberDaysForPayBet = item.minimumNumberDaysForPayBet != null ? item.minimumNumberDaysForPayBet : 0;
        this.form.maximumNumberDaysForPayBet = item.maximumNumberDaysForPayBet != null ? item.maximumNumberDaysForPayBet : 0;
        this.form.retailCanPayTicketOfOtherRetail = item.retailCanPayTicketOfOtherRetail != null ? item.retailCanPayTicketOfOtherRetail : false;
        this.form.enableMobileBottomToolbar = item.enableMobileBottomToolbar != null ? item.enableMobileBottomToolbar : false;
        this.form.assignRetailBetToAgency = item.assignRetailBetToAgency != null ? item.assignRetailBetToAgency : true;
        this.form.disableCheckOnAntepostOdds = item.disableCheckOnAntepostOdds != null ? item.disableCheckOnAntepostOdds : false;
        this.form.disableSessionCheck = item.disableSessionCheck != null ? item.disableSessionCheck : false;
        this.form.explodeMarketOnMobileDevice = item.explodeMarketOnMobileDevice != null ? item.explodeMarketOnMobileDevice : false;
        this.form.showMultiplicityLegendMobile = item.showMultiplicityLegendMobile != null ? item.showMultiplicityLegendMobile : false;
        this.form.enableTreeWithPlayerOutcomes = item.enableTreeWithPlayerOutcomes != null ? item.enableTreeWithPlayerOutcomes : false;
        this.form.proxyUrl = item.proxyUrl != undefined ? item.proxyUrl : null;
        this.form.useServerSideRules = item.useServerSideRules != null ? item.useServerSideRules : false;
        this.form.allowIntegralLiveBet = item.allowIntegralLiveBet != null ? item.allowIntegralLiveBet : false;
        this.form.betCodeViewSelector = item.betCodeViewSelector != null ? item.betCodeViewSelector : 'long';
        this.form.useWalletAgency = item.useWalletAgency != null ? item.useWalletAgency : true;
        this.form.disableIdleUICheck = item.disableIdleUICheck != null ? item.disableIdleUICheck : false;
        this.dialog = true;
      },

      async submit() {

        await this.setPreload(true);

        let url = '';
        if (this.actionForm == "create") {
          url = this.getUrlRule + 'skins/create'
        } else if (this.actionForm == "update" && this.idSkin != -1) {
          url = this.getUrlRule + 'skins/update/' + this.idSkin
        }

        if (this.form.bookieUserName === '') {
          this.form.bookieUserName = null;
        }
        if (this.form.bookiePassword === '') {
          this.form.bookiePassword = null;
        }
        if (this.form.widgetFieldUrl === '') {
          this.form.widgetFieldUrl = null;
        }
        if (this.form.widgetFieldUsername === '') {
          this.form.widgetFieldUsername = null;
        }
        if (this.form.widgetFieldPassword === '') {
          this.form.widgetFieldPassword = null;
        }
        if (this.form.percentageTaxValueOnWon == null) {
          this.form.percentageTaxValueOnWon = 0
        }
        if(this.form.enableAgencyCanPlayForUser == null){
          this.form.enableAgencyCanPlayForUser = false;
        }
        if(this.form.maxTimeToDeleteBetMs == null){
          this.form.maxTimeToDeleteBetMs = 180000
        }

        if(this.form.enableMonedero == null){
          this.form.enableMonedero = false;
        }

        if(this.form.enableNetUserBlockedCheck == null){
          this.form.enableNetUserBlockedCheck = false;
        }

        if(this.form.rejectBetTimeLiveOddExpired == null){
          this.form.rejectBetTimeLiveOddExpired = false;
        }

        if(this.form.minimumNumberDaysForPayBet == null){
          this.form.minimumNumberDaysForPayBet = 0;
        }

        if(this.form.maximumNumberDaysForPayBet == null){
          this.form.maximumNumberDaysForPayBet = 0;
        }

        if(this.form.retailCanPayTicketOfOtherRetail == null){
          this.form.retailCanPayTicketOfOtherRetail = false;
        }

        if(this.form.enableMobileBottomToolbar == null){
          this.form.enableMobileBottomToolbar = false;
        }

        if(this.form.assignRetailBetToAgency == null){
          this.form.assignRetailBetToAgency = true;
        }

        if(this.form.disableCheckOnAntepostOdds == null){
          this.form.disableCheckOnAntepostOdds = false;
        }

        if(this.form.disableSessionCheck == null){
          this.form.disableSessionCheck = false;
        }

        if(this.form.explodeMarketOnMobileDevice == null){
          this.form.explodeMarketOnMobileDevice = false;
        }

        if(this.form.showMultiplicityLegendMobile == null){
          this.form.showMultiplicityLegendMobile = false;
        }

        if(this.form.enableTreeWithPlayerOutcomes == null){
          this.form.enableTreeWithPlayerOutcomes = false;
        }

        if (this.form.proxyUrl === '') {
          this.form.proxyUrl = null;
        }

        if(this.form.useServerSideRules == null){
          this.form.useServerSideRules = false;
        }

        if(this.form.allowIntegralLiveBet == null){
          this.form.allowIntegralLiveBet = false;
        }

        if(this.form.betCodeViewSelector == null){
          this.form.betCodeViewSelector = 'long';
        }

        if(this.form.useWalletAgency == null){
          this.form.useWalletAgency = true;
        }

        if(this.form.disableIdleUICheck == null){
          this.form.disableIdleUICheck = false;
        }

        if (!isNumber(this.form.percentageTaxValueOnWon)) {
          alert(this.$t('percentageTaxOnWonNeedBePositiveNumber'))
          await this.setPreload(false);
          return
        }


        let payload = this.form;
        this.messages = [];

        await axios.post(url, payload, {'headers': {'Authorization': `Bearer ${this.getToken}`}})
                .then(res => {

                  this.fetchSkins();
                  this.dialog = false;

                  this.snackbar.color = "success";
                  this.snackbar.text = (this.actionForm == "create") ? "Skin inserita con successo" : "Skin aggiornata con successo";
                  this.snackbar.snackbar = true;
                  this.snackbar.mode = 'multi-line';
                  this.snackbar.timeout = 5000;

                  this.resetForm();

                })

                .catch(err => {
                  console.log(err)
                  if (err.response) {

                    var msg_error = '';

                    if (err.response.data.error != undefined) {

                      err.response.data.error.forEach(value => {
                        this.messages[value.param] = value.msg
                      })

                      msg_error = err.response.statusText;

                    } else {

                      msg_error = err.response.data.message

                    }


                    this.snackbar.color = "error";
                    this.snackbar.text = msg_error;
                    this.snackbar.snackbar = true;
                    this.snackbar.mode = 'multi-line';
                    this.snackbar.timeout = 5000;

                    this.setPreload(false);

                    if (err.response.status === 401) {
                      this.setLogout();
                      this.$router.push('/login')
                    }

                  }

                });

        await this.setPreload(false);

      },

      resetForm() {
        this.form.name = '';
        this.form.website = '';
        this.form.active = true;
        this.form.quickbet = false;
        this.form.eventday = false;
        this.form.nextevent = false;
        this.form.showquickbet = false;
        this.form.cashout = false;
        this.form.cashoutlive = false;
        this.form.shockabsorber = false;
        this.form.voidAsLose = false;
        this.form.settings.skin_id = '';
        this.form.settings.save_endpoint = '';
        this.form.settings.print_endpoint = '';
        this.form.settings.send_bet_endpoint = '';
        this.form.settings.typeOdds = '';
        this.form.settings.timezone = '';
        this.form.settings.language = '';
        this.form.settings.sircms_endpoint = '';
        this.form.settings.enableRisk = '';
        this.form.settings.stream_live = false;
        this.form.settings.enableTimeLive = '';
        this.form.tokenTerminal = null;
        this.idSkin = -1;
        this.messages = [];
        this.form.gameConsole = null;
        this.form.featuredTournamentWidget = false;
        this.form.featuredTournamentAutoLoad = false;
        this.form.enableClearCartAtPlaceBet = false;
        this.form.signUp = false;
        this.form.ai_safebet = false;
        this.form.bookieUserName = null;
        this.form.bookiePassword = null;
        this.form.widgetFieldUrl = null;
        this.form.widgetFieldUsername = null;
        this.form.widgetFieldPassword = null;
        this.form.enabledTaxOnWon = false;
        this.form.percentageTaxValueOnWon = 0;
        this.form.enableAgencyCanPlayForUser = false;
        this.form.enableMonedero = false;
        this.form.enableNetUserBlockedCheck = false;
        this.form.rejectBetTimeLiveOddExpired = false;
        this.form.maxTimeToDeleteBetMs = 180000;
        this.form.minimumNumberDaysForPayBet = 0;
        this.form.maximumNumberDaysForPayBet = 0;
        this.form.retailCanPayTicketOfOtherRetail = false;
        this.form.enableMobileBottomToolbar = false;
        this.form.assignRetailBetToAgency = true;
        this.form.disableCheckOnAntepostOdds = false;
        this.form.disableSessionCheck = false;
        this.form.explodeMarketOnMobileDevice = false;
        this.form.showMultiplicityLegendMobile = false;
        this.form.enableTreeWithPlayerOutcomes = false;
        this.form.proxyUrl = null;
        this.form.useServerSideRules = false;
        this.form.allowIntegralLiveBet = false;
        this.form.betCodeViewSelector = 'long';
        this.form.useWalletAgency = true;
        this.form.disableIdleUICheck = false;
      },

      deleteItem(item) {
        this.dialogConfirm = true;
        this.titleDialog = 'Sei sicuro ?'
        this.idSkin = item.platform_id;
      },
      async confirmDelete() {

        this.dialogConfirm = false;
        await this.setPreload(true);
        var url = this.getUrlRule + 'skins/delete/' + this.idSkin
        this.messages = [];

        axios.delete(url, {'headers': {'Authorization': `Bearer ${this.getToken}`}})

                .then(res => {

                  this.fetchSkins();

                  this.snackbar.color = "success";
                  this.snackbar.text = "Skin Eliminata con  successo";
                  this.snackbar.snackbar = true;
                  this.snackbar.mode = 'multi-line';
                  this.snackbar.timeout = 5000;

                  this.setPreload(false);

                })
                .catch(err => {

                  console.log(err)
                  if (err.response) {

                    var msg_error = '';

                    if (err.response.data.error != undefined) {

                      err.response.data.error.forEach(value => {
                        this.messages[value.param] = value.msg
                      })

                      msg_error = err.response.statusText;

                    } else {

                      msg_error = err.response.data.message

                    }


                    this.snackbar.color = "error";
                    this.snackbar.text = msg_error;
                    this.snackbar.snackbar = true;
                    this.snackbar.mode = 'multi-line';
                    this.snackbar.timeout = 5000;

                    this.setPreload(false);

                    if (err.response.status === 401) {
                      this.setLogout();
                      this.$router.push('/login')
                    }

                  }


                });

      },
      loadLanguageList() {
        this.languages = [];
        const languageList = this.getLanguagesList;

        for (const lang of languageList) {
          this.languages.push({
            text: lang.text,
            value: lang.abbr
          })
        }
      }

    },

  }
</script>

<style scoped>

</style>
